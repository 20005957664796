<template>
  <div class="payResult">
    <div class="payResult-content">
      <nav-bar
        :bgc="'background:#fff'"
        :goBack="goBack"
        :title="title"
      ></nav-bar>
      <img
        class="pay-result"
        v-if="payResult.type === 'success'"
        src="@/assets/image/user/payResult.png"
        alt=""
      />
      <img
        class="pay-result"
        v-else
        src="@/assets/image/user/error.png"
        alt=""
      />
      <div class="pay-result-text">
        <!-- 在线支付成功 -->
        <div class="title" v-if="payResult.type === 'success'">
          {{ payResult.pay == "1" ? $t("支付成功") : $t("訂單提交成功") }}
        </div>
        <div class="orderno">
          {{
            payResult.type === "success"
              ? `${$t("訂單號")}：${payResult.orderId}`
              : $t("支付遇到問題，請嘗試重新支付")
          }}
        </div>
        <div class="online">
          {{
            `${
              payResult.pay === "1"
                ? `${$t("在線付款")}：`
                : `${$t("銀行轉賬")}：`
            }${formatNumber(payResult.price)}`
          }}
        </div>
      </div>
      <div class="pay-result-account" v-if="payResult.html">
        <div class="pay-result-account-text">
          <div class="tips" v-html="payResult.tips"></div>
          <div class="tips" v-html="payResult.html"></div>
        </div>
      </div>
      <!-- <div class="pay-result-btn">
        <div class="pay-back-home">返回首頁</div>
        <div class="pay-view">查看訂單</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import mixins from "@/utils/mixins.js";
export default {
  mixins: [mixins],
  data() {
    return {
      title: "",
      payResult: {}
    };
  },
  created() {
    this.payResult = this.$route.query;
    if (this.payResult.type === "success") {
      if (this.payResult.html) {
        this.title = this.$t("訂單提交成功");
      } else {
        this.title = this.$t("訂單支付成功");
      }
    } else {
      this.title = this.$t(this.payResult.message);
    }
  },
  methods: {
    goBack() {
      const path = window.localStorage.getItem("productPath");
      if (path) {
        this.$router.push(path);
      } else {
        this.$router.back();
      }
    }
  }
};
</script>

<style lang="scss">
.pay-result {
  width: 120px;
  margin: auto;
  padding-top: 80px;
}
.payResult-content {
  background-color: #fff;
  min-height: 100vh;
}
.pay-result-account {
  padding: 28px;
  .pay-result-account-text {
    padding: 12px;
    background: #f8f8f8;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #f2f3f3;
    .tips {
      font-size: 14px;
      color: #f82207;
    }
  }
}
.pay-result-text {
  text-align: center;
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 20px;
    color: #1a1a1a;
    margin-bottom: 4px;
  }
  .orderno,
  .online {
    padding: 0 16px;
    font-size: 16px;
    color: #999999;
  }
}
.pay-result-btn {
  padding: 0 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .pay-back-home {
    margin-top: 24px;
    color: #fff;
    background: #90d12e;
  }
  .pay-view {
    background: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
    color: #666666;
  }
  .pay-back-home,
  .pay-view {
    width: 100%;
    padding: 10px 0;
    border-radius: 8px 8px 8px 8px;
    flex: 1;
    text-align: center;
    margin-bottom: 12px;
  }
}
</style>
